import { getLocalURL, getURL } from '../helpers/Utils';

export const JAVA_HOST = getLocalURL('OMS_JAVA_HOST'); // process.env.OMS_JAVA_HOST; // 'https://dev-api.stylifashion.com';
export const SERVER_HOST = getLocalURL('OMS_API_HOST'); //process.env.OMS_API_HOST; // 'https://oms-dev.stylishop.store';
export const TRACKING_HOST = getLocalURL('OMS_TRACKING_HOST'); //process.env.OMS_TRACKING_HOST; // '//stylishop.clickpost.in';
export const FRONTEND_HOST = process.env.OMS_FRONTEND_HOST; // 'https://dev.stylifasion.com';
export const AUTH_SERVICE_URL = process.env.OMS_AUTH_SERVICE_URL;
export const INVENTORY_HOST = getLocalURL('OMS_INV_HOST'); //process.env.OMS_INV_HOST; // 'http://api.inventory-dev.stylishop.store';
export const NAVIK_HOST = getLocalURL('OMS_NAVIK_HOST'); //process.env.OMS_NAVIK_HOST; // 'https://track.stylishop.com/';
export const ADDRESS_MAPPER_HOST = getLocalURL('OMS_ADDRESS_MAPPER_HOST'); //process.env.ADDRESS_MAPPER_HOST; // 'https://adrsmpr-dev.stylishop.store/';

export const stores =
  localStorage.getItem('LOCATION') === 'IN'
    ? [
        {
          value: '51',
          label: 'INDIA - English',
          currency: 'INR',
          country: 'IN',
          lang: 'en'
        }
      ]
    : [
        {
          value: '1',
          label: 'KSA - English',
          currency: 'SAR',
          country: 'SA',
          lang: 'en'
        },
        {
          value: '3',
          label: 'KSA - Arabic',
          currency: 'SAR',
          country: 'SA',
          lang: 'ar'
        },
        {
          value: '7',
          label: 'UAE - English',
          currency: 'AED',
          country: 'AE',
          lang: 'en'
        },
        {
          value: '11',
          label: 'UAE - Arabic',
          currency: 'AED',
          country: 'AE',
          lang: 'ar'
        },
        {
          value: '12',
          label: 'KUWAIT - English',
          currency: 'KWD',
          country: 'KW',
          lang: 'en'
        },
        {
          value: '13',
          label: 'KUWAIT - Arabic',
          currency: 'KWD',
          country: 'KW',
          lang: 'ar'
        },
        {
          value: '15',
          label: 'QATAR - English',
          currency: 'QAR',
          country: 'QA',
          lang: 'en'
        },
        {
          value: '17',
          label: 'QATAR - Arabic',
          currency: 'QAR',
          country: 'QA',
          lang: 'ar'
        },
        {
          value: '19',
          label: 'BAHRAIN - English',
          currency: 'BHD',
          country: 'BH',
          lang: 'en'
        },
        {
          value: '21',
          label: 'BAHRAIN - Arabic',
          currency: 'BHD',
          country: 'BH',
          lang: 'ar'
        }
      ];

export const locations = [
  { label: 'GCC', value: 'GULF', key: 0 },
  { label: 'INDIA', value: 'IN', key: 1 }
];

export const sources = [
  { value: '0', label: 'Web', valueText: 'web' },
  { value: '1', label: 'App', valueText: 'app' },
  { value: '2', label: 'M Site', valueText: 'msite' },
  { value: '3', label: 'Admin', valueText: 'admin' }
];

export const statuses = [
  // { value: 'pending', label: 'Pending' },
  { value: 'processing', label: 'Processing' },
  { value: 'packed', label: 'Packed' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'closed', label: 'Closed' },
  // { value: 'complete', label: 'Complete' },
  { value: 'canceled', label: 'Canceled' },
  // { value: 'corrupted', label: 'Corrupted' },
  { value: 'undelivered', label: 'UnDelivered' },
  // { value: 'payfort_fort_new', label: 'Payfort New Order' },
  // { value: 'payfort_fort_failed', label: 'Payfort Failed' },
  { value: 'payment_failed', label: 'Payment Failed' },
  { value: 'pending_payment', label: 'Pending Payment' },
  { value: 'payment_canceled', label: 'Payment Canceled' },
  { value: 'rto', label: 'RTO' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'rto_initiated', label: 'RTO Initiated' },
  { value: 'payment_hold', label: 'Payment Hold' },
  { value: 'order_lost', label: 'Order Lost' },
  { value: 'order_cancelled', label: 'Order Cancelled' }
];

export const websites =
  localStorage.getItem('LOCATION') === 'IN'
    ? [{ code: '51', name: 'India', locale: 'in', currency: 'INR' }]
    : [
        { code: '1', name: 'Saudi Arabia', locale: 'sa', currency: 'SAR' },
        { code: '3', name: 'UAE', locale: 'ae', currency: 'AED' },
        { code: '4', name: 'Kuwait', locale: 'kw', currency: 'KWD' },
        { code: '5', name: 'Qatar', locale: 'qa', currency: 'QAR' },
        { code: '7', name: 'Bahrain', locale: 'bh', currency: 'BHD' }
      ];

export const genders = [
  { code: '1', label: 'Male' },
  { code: '2', label: 'Female' },
  { code: '3', label: 'Not Specified' }
];

export const ageGroups = [
  { code: '1', label: 'Less than 18 years' },
  { code: '2', label: 'Between 18-25 years' },
  { code: '3', label: 'Between 25-30 years' },
  { code: '4', label: 'Between 30-35 years' },
  { code: '5', label: 'Over 35 years' }
];

export const orderFormCountries = [
  { value: '1', label: 'KSA' },
  { value: '7', label: 'UAE' },
  { value: '15', label: 'QATAR' },
  { value: '19', label: 'BAHRAIN' },
  { value: '12', label: 'KUWAIT' },
  { value: '51', label: 'INDIA' }
];

export const easLedgerType = [
  { value: 0, label: 'ALL' },
  { value: 1, label: 'EARN' },
  { value: 2, label: 'SPEND' },
  { value: 3, label: 'EXPIRED' }
];

export const PAYMENT_COD = 'cashondelivery';
export const PAYMENT_STORE_CREDIT = 'free';

export const PAYMENT_COD_ENUM = 'CASH_ON_DELIVERY';
export const PAYMENT_STORE_CREDIT_ENUM = 'FREE';

export const getFlag = () => {
  return localStorage.getItem('LOCATION') === 'IN'
  ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
  : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';
}

export const getPods = () => {
  return localStorage.getItem('LOCATION') === 'IN' ? 'IN' : 'GCC';
}
