import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' }
];
// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
// const firebaseConfigQA = {
//   apiKey: 'AIzaSyCM8p8y22CgYwMdjiKLFfl3lWQ3cf_N1H8',
//   authDomain: 'supply-qa.firebaseapp.com',
//   projectId: 'supply-qa',
//   storageBucket: 'supply-qa.appspot.com',
//   messagingSenderId: '428145837378',
//   appId: '1:428145837378:web:eeeccba92b0495eec2f4db'
// };
// const firebaseConfigDev = {
//   apiKey: 'AIzaSyC4wof5tD1gm6SQsM1gnJ4QgE_kncX6MZc',
//   authDomain: 'styli-dev-251710.firebaseapp.com',
//   databaseURL: 'https://styli-dev-251710.firebaseio.com',
//   projectId: 'styli-dev-251710',
//   storageBucket: 'styli-dev-251710.appspot.com',
//   messagingSenderId: '903334203234',
//   appId: '1:903334203234:web:2aebed67a596649813e63b',
//   measurementId: 'G-5ZG7GZ77CH'
// };
const firebaseConfigDev = {
  apiKey: "AIzaSyBXprkSsdWnA8Ks9HJ7XAjAvt9bcO_iAkQ",
  authDomain: "stylishopdev.firebaseapp.com",
  projectId: "stylishopdev",
  storageBucket: "stylishopdev.appspot.com",
  messagingSenderId: "640935596576",
  appId: "1:640935596576:web:393a09830cb8b64e672245",
  measurementId: "G-WDEYHW4RET"
};

// const firebaseConfigDev = {
//   apiKey: 'AIzaSyC4wof5tD1gm6SQsM1gnJ4QgE_kncX6MZc',
//   authDomain: 'styli-dev-251710.firebaseapp.com',
//   databaseURL: 'https://styli-dev-251710.firebaseio.com',
//   projectId: 'styli-dev-251710',
//   storageBucket: 'styli-dev-251710.appspot.com',
//   messagingSenderId: '903334203234',
//   appId: '1:903334203234:web:2aebed67a596649813e63b',
//   measurementId: 'G-5ZG7GZ77CH'
// };

// const firebaseConfigProd = {
//   apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
//   authDomain: 'stylishopprod.firebaseapp.com',
//   projectId: 'stylishopprod',
//   storageBucket: 'stylishopprod.appspot.com',
//   messagingSenderId: '476895285940',
//   appId: '1:476895285940:web:de3987e17df783e6ba3de1',
//   measurementId: 'G-W15Q6Z4J5D'
// };
const firebaseConfigProd = {
apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
authDomain: 'stylishopprod.firebaseapp.com',
projectId: 'stylishopprod',
storageBucket: 'stylishopprod.appspot.com',
messagingSenderId: '476895285940',
appId: '1:476895285940:web:3897b49635753e08ba3de1',
measurementId: 'G-5VP371TRY8',
};


export const firebaseConfig =
  process.env.NODE_ENV === 'development'
    ? firebaseConfigDev
    : process.env.NODE_ENV === 'uat'
    ? firebaseConfigDev
    : process.env.NODE_ENV === 'production'
    ? firebaseConfigProd
    : firebaseConfigDev; // for QA

export const currentUser = {
  id: 1,
  title: '',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const adminRoot = '/app';
export const otpRoot = '/user/verify-otp'
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
// export const servicePath = 'https://vm-dev.stylishop.store';
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.yellowgranola';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel'
];
